'use client'
import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'

const StatRoot = styled('div', {
  name: 'MuiStat', // The component name
  slot: 'root' // The slot name
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 600,
  padding: theme.spacing(3, 4),
  [theme.breakpoints.down('md')]: {
    borderRight: 'none'
  }
}))

const StatValue = styled('div', {
  name: 'MuiStat',
  slot: 'value'
})(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '4rem'
}))

const StatUnit = styled('div', {
  name: 'MuiStat',
  slot: 'unit'
})(() => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textAlign: 'center',
}))

const AppStatChecker = forwardRef(function AppStatChecker(props, ref) {
  const { value, unit, ...other } = props

  return (
    <StatRoot ref={ref} {...other}>
      <StatValue>{value}</StatValue>
      <StatUnit>{unit}</StatUnit>
    </StatRoot>
  )
})

export default AppStatChecker

