import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Avatar/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Box/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Card/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/CardContent/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/CardHeader/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Container/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Grid/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/List/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/ListItem/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/ListItemIcon/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/@mui/material/node/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/public/home/categories.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/public/listicon.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/src/components/AppCarousel.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/foundation-website/src/components/Legos/Stats.js")