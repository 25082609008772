'use client'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import AppStatChecker from '@/src/components/AppStatChecker'
import theme from '@/src/components/ThemeRegistry/theme';
import { ACHIEVEMENT_SCORES } from '@/src/utils/constants'


const Stats = () => <Container
    sx={{
        border: '1px solid #CECCCC',
        marginX: { xs: 3, md: 5 },
        marginBottom: 5,
        paddingBottom: 5,
        borderRadius: 2
    }}
>
    <Typography
        variant='h2'
        sx={{ padding: { xs: 3, md: 5 } }}
        aria-label='WinVinaya Achievement Scores'
    >
        WinVinaya Achievement Scores
    </Typography>
    <Grid
        container
        spacing={2}
    >
        {ACHIEVEMENT_SCORES.map(dataObj => (
            <Grid
                item
                lg={3}
                sm={6}
                xs={12}
                key={dataObj.unit}
                sx={{

                    borderRight: '1px solid #CECCCC',
                    [theme.breakpoints.down('sm')]: {
                            borderRight: 'none'
                    },                    
                    [theme.breakpoints.between('sm', 'lg')]: {
                        [`&:nth-of-type(even)`]: {
                            borderRight: 'none'
                        }
                    },
                    [theme.breakpoints.up('lg')]: {
                        [`&:nth-of-type(4n)`]: {
                            borderRight: 'none'
                        }
                    }
                }}
            >
                <AppStatChecker
                    value={dataObj.value}
                    unit={dataObj.unit}
                    key={dataObj.unit}
                />
            </Grid>
        ))}
    </Grid>
</Container >

export default Stats
